import * as React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import { darken } from "@mui/material";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import LoginIcon from "@mui/icons-material/Login";
import { useTranslation } from "react-i18next";

import LoyaltyIcon from "@mui/icons-material/Loyalty";

const CustomMenu = styled(Menu)(
  ({ theme }) => `
& .MuiMenu-paper{
color: ${theme.palette.primary.contrastText};
background: ${theme.palette.primary.main};

:hover {
color: ${darken(theme.palette.primary.contrastText, 0.2)};
}
}
`
);

export default function UserMenu() {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (action) => {
    setAnchorEl(null);
    switch (action) {
      case "flyers":
        navigate("/flyers");
        break;
      case "signing":
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <IconButton color="inherit" onClick={handleClick}>
        <MenuIcon />
      </IconButton>
      <CustomMenu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={() => handleClose("flyers")}>
          <ListItemIcon>
            <AutoStoriesIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("flyers")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleClose("cards")}>
          <ListItemIcon>
            <LoyaltyIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("about")}</ListItemText>
        </MenuItem>
        {/* <MenuItem onClick={() => handleClose("signin")}>
          <ListItemIcon>
            <LoginIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("forms:signIn")}</ListItemText>
        </MenuItem> */}
      </CustomMenu>
    </div>
  );
}
