import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from './styles/theme';
import './styles/index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { BrowserRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './translations/config.js';
import A2HS from './components/A2HS';
// import reportWebVitals from './reportWebVitals';

const Site = () => {
  const {t} = useTranslation();
    return (
        <React.StrictMode>
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <A2HS />
                    <div className="rotate-notify">{t("upright")}</div>
                    <App />
                </ThemeProvider>
            </BrowserRouter>
         </React.StrictMode>
    );
};

// const root = createRoot(document.getElementById('root'));
// root.render(<Site />);
ReactDOM.render(<Site />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
