import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useSharedState, { createSharedState } from './hooks/useSharedState';
import  { Toaster } from 'react-hot-toast';
import AppContainer from './components/AppContainer';
import Home from "./screens/Home";
import FlyerList from "./screens/FlyerList";
import Flyer from "./screens/Flyer";


function App() {
  createSharedState('app', { url: '/', flyer: null, startup: true, visible: true });
  const [, setAppState] = useSharedState('app');
  const navigate = useNavigate();
  const { t } = useTranslation();

  function handleVisibilityChange() {
    setAppState({
        visible: document.visibilityState === 'hidden' ? false : true,
        startup: window.location.pathname === '/' ? true : false,
    });
}
  
  return (
    <>
      <Toaster
                toastOptions={{
                    success: {
                        style: {
                            boxSizing: "border-box",
                            backgroundColor:"#fafafa",
                            border: '3px double #840000',
                            padding: '4px 16px',
                            color: '#840000',
                          },
                          iconTheme: {
                            primary: '#840000',
                            secondary: '#FFFAEE',
                          },
                    },
                    error: {
                        style: {
                            background: 'red',
                        },
                    },
                }}
                containerStyle={{
                    top: 6,
                    left: 20,
                    bottom: 20,
                    right: 20,
                }}
            />
            <AppContainer id="app">
                <Routes>
                    <Route path="" element={<Home />} />
                    <Route path="/flyers" element={<FlyerList />} />
                    <Route path="/:flyerId" element={<Flyer />} />
                </Routes>
            </AppContainer>
    </>
  );
}

export default App;
