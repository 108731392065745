import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import db from "../core/db";
import { useLiveQuery } from "dexie-react-hooks";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ConfirmDialog from "../components/ConfirmDialog";

const InfoWrapper = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 42px;
  background-color: ${(p) => p.theme.palette.secondary.main};
  color: ${(p) => p.theme.palette.secondary.contrastText};
  z-index: 2;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 4px 4px 16px;
  font-weight: 700;

  &.shadow {
    box-shadow: 0px 15px 30px -15px #5a7576;
  }

  & .stroke {
    color: #000;
  }

  & .title {
    font-size: 18px;
  }

  & .subtitle {
    font-size: 14px;
  }

  &.bottom {
    top: auto;
    bottom: 0;
    & > div {
      justify-content: center;
    }
  }
`;

const StyledListItem = styled(ListItem)`
  padding-right: 8px;
  &.odd {
    background-color: rgba(0, 0, 0, 0.01);
  }
`;

const FlyerList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [dialogState, setDialogState] = useState({
    open: false,
    title: "dialog",
    content: [],
    userData: "",
  });

  const filteredFlyers = useLiveQuery(
    async () => {
      const flyers = await db.flyers.toCollection().sortBy("name");
      // Return result
      return flyers || [];
    },
    // specify vars that affect query:
    []
  );

  const handleFlyer = (ev, fid) => {
    ev.preventDefault();
    ev.stopPropagation();
    navigate(`/${fid}`);
  };

  const handleFlyerDelete = (fid, name) => {
    setDialogState({
      open: true,
      title: t("deleteFlyer"),
      content: [t("deleteFlyerDesc"), name],
      userData: fid,
    });
  };

  const handleConfirmDelete = async (result, userData) => {
    if (result === "1") await db.flyers.where("fid").equals(userData).delete();
    setDialogState((state) => ({ ...state, open: false }));
  };

  return (
    <>
      <InfoWrapper style={{ paddingLeft: " 8px", paddingRight: "8px" }}>
        <Button
          color="primary"
          variant="text"
          size="small"
          sx={{
            maxHeight: "32px",
            maxWidth: "42px",
            minWidth: "42px",
            paddingLeft: "0px",
          }}
        >
          <ArrowBackIcon
            sx={{ fontSize: 32 }}
            onClick={() => {
              navigate(-1);
            }}
          />
        </Button>
        <div className="subtitle">{t("flyers")}</div>
        <div style={{ width: "42px" }} />
      </InfoWrapper>
      <Box sx={{ width: "100%", overflowY: "auto" }}>
        <List>
          {filteredFlyers?.map((fly, idx) => (
            <StyledListItem
              key={fly.fid}
              disablePadding
              className={idx % 2 === 0 ? "even" : "odd"}
            >
              <ListItemButton onClick={(ev) => handleFlyer(ev, fly.fid)}>
                <AutoStoriesIcon sx={{ marginRight: "4px" }} />
                <ListItemText primary={`${fly.name}`} />
              </ListItemButton>
              <DeleteForeverIcon
                sx={{ margin: "0px 8px" }}
                onClick={() => {
                  handleFlyerDelete(fly.fid, fly.name);
                }}
              />
            </StyledListItem>
          ))}
        </List>
      </Box>
      <ConfirmDialog
        open={dialogState.open}
        title={dialogState.title}
        content={dialogState.content}
        resultCb={handleConfirmDelete}
        userData={dialogState.userData}
        confirmText={t("delete")}
      />
    </>
  );
};

export default FlyerList;
