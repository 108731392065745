import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import default_en from "./en/default.json";
import default_hr from "./hr/default.json";

import forms_en from "./en/forms.json";
import forms_hr from "./hr/forms.json";

export const defaultNS = "default";
export const resources = {
  en: {
    default: default_en,
    forms: forms_en,
  },
  hr: {
    default: default_hr,
    forms: forms_hr,
  },
};

const supportedLanguages=["en","hr"];

let lang = localStorage.getItem("lang") || navigator.language.split("-")[0].toLowerCase();
if(!supportedLanguages.includes(lang)) lang="en";

i18n.on("languageChanged", (lng) => {
  localStorage.setItem("lang", lng);
});

i18n.use(initReactI18next).init({
  lng: lang,
  fallbackLng: "en",
  // ns: ["default", "forms"],
  defaultNS,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources,
});

