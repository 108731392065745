import styled from 'styled-components';
import React, { useState } from 'react';
import QrReader from 'react-qr-reader';
import { ReactComponent as SvgLogo } from '../assets/icons/qr-hr.svg';

const QrWrapper = styled.div`
    position: relative;
    width: 100%;
    padding: 20px;
    & div {
        border: 32px solid rgba(0, 0, 0, 0.1);
    }

    & div,
    & section {
        border-radius: 16px;
        box-shadow: rgb(255 255 255 / 50%) 0px 0px 0px 4px inset !important;
    }
`;

const PlaceHolder = styled.section`
    overflow: hidden;
    position: relative;
    width: 100%;
    padding-top: 100%;
    border-radius: 30px;
`;

const PHInner = styled.div`
    top: 0px;
    left: 0px;
    z-index: 1;
    box-sizing: border-box;
    border: 32px solid rgba(0, 0, 0, 0.1);
    box-shadow: rgba(255, 0, 0, 0.5) 0px 0px 0px 5px inset;
    position: absolute;
    width: 100%;
    height: 100%;
`;

const LogoSvg = styled(SvgLogo)`
    width: 100%;
    height: 100%;
    border-radius: 4px;
    padding: 0px;
`;

export default function QrScanner({ onResult, open = false }) {
    const handleScan = (data) => {
        if (data) {
            onResult && onResult(data);
        }
    };
    const handleError = (err) => {
        console.error(err);
    };

    return (
        <QrWrapper>
            {open ? (
                <QrReader delay={300} onError={handleError} onScan={handleScan} style={{ width: '100%' }} />
            ) : (
                <PlaceHolder>
                    <PHInner>
                        <LogoSvg />
                    </PHInner>
                </PlaceHolder>
            )}
        </QrWrapper>
    );
}
