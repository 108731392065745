import { useEffect, useState } from 'react';

let listeners = {};
let state = {};
let fileNames = {};

const getSharedState = (branch) => state[branch];

const setState = (newState, branch, save = false) => {
    if (newState instanceof Object && newState != null) {
        state[branch] = { ...state[branch], ...newState };
    } else state[branch] = newState;
    if (save) saveSharedState(branch);
    if (listeners[branch] === undefined) listeners[branch] = [];

    listeners[branch].forEach((listener) => {
        listener(state[branch]);
    });
};

const saveSharedState = (branch) => {
    if (state[branch] === undefined || !fileNames[branch].length) return false;
    localStorage.setItem(fileNames[branch] + '_shared_state', JSON.stringify(state[branch]));
};

const createSharedState = (branch, initialState = {}, fileName = '') => {
    if (state[branch] !== undefined) return false;
    fileNames[branch] = fileName;
    if (fileName.length) {
        let savedState = localStorage.getItem(fileName + '_shared_state');
        state[branch] = savedState === null ? initialState : JSON.parse(savedState);
        if (savedState === null) saveSharedState(branch);
    } else state[branch] = initialState;

    return true;
};

const useSharedState = (branch) => {
    if (listeners[branch] === undefined) listeners[branch] = [];
    const newListener = useState()[1];
    useEffect(() => {
        listeners[branch].push(newListener);
        return () => {
            listeners[branch] = listeners[branch].filter((listener) => listener !== newListener);
        };
    }, []);

    return [
        state[branch],
        (newState, save = false) => {
            setState(newState, branch, save);
        },
    ];
};

export default useSharedState;
export { setState as setSharedState, getSharedState, createSharedState };
