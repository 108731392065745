//global
import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
//mui
import Fab from "@mui/material/Fab";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CameraIcon from "@mui/icons-material/Camera";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import TranslateIcon from "@mui/icons-material/Translate";
import { Button } from "@mui/material";
//local
import QrScanner from "../components/QRScanner";
import UserMenu from "../components/UserMenu";

const StyledFab = styled(Fab)`
  position: absolute;
  z-index: 1;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  height: 40px;
  border-radius: 8px !important;
  min-width: 160px;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 112px);
`;

const PngIcon = styled.img`
  width: 24px;
  height: 24px;
`;


const actions = [
  { icon: <PngIcon src="/gui/flags/en.png" />, name: "english", code: "en" },
  { icon: <PngIcon src="/gui/flags/hr.png" />, name: "hrvatski", code: "hr" },
];

function TopAppBar({ t }) {
  const [isOpen, setIsOpen] = useState(false);
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const handleLangChange = (code) => {
    setIsOpen(false);
    i18n.changeLanguage(code);
  };

  return (
    <Box sx={{ flexGrow: 0, marginBottom: "40px" }}>
      <AppBar position="static" sx={{ boxShadow: 0 }}>
        <Toolbar>
          <SpeedDial
            open={isOpen}
            onOpen={() => {
              setIsOpen(true);
            }}
            onClose={() => {
              setIsOpen(false);
            }}
            direction="down"
            FabProps={{ size: "small", color: "primary" }}
            ariaLabel="SpeedDial"
            sx={{ position: "absolute", left: 12, top: 8 }}
            icon={
              <SpeedDialIcon
                icon={
                  <PngIcon src={`/gui/flags/${i18n.language || "en"}.png`} />
                }
                openIcon={<TranslateIcon />}
              />
            }
          >
            {actions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={() => handleLangChange(action.code)}
              />
            ))}
          </SpeedDial>
          <Box sx={{ flexGrow: 1 }}></Box>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => {
              navigate("/flyers");
            }}
          >
            {t("flyers")}
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

const Home = () => {
  const { t } = useTranslation();
  
  const [qrOpen, setQrOpen] = useState({ show: false });
  const navigate = useNavigate();
  

  const scanQR = () => {
    setQrOpen((state) => ({ show: !state.show }));
  };

  const onResult = (data) => {
    setQrOpen({ show: false });
    if (data.startsWith("https://")) {
        const url = new URL(data);
        if(url.host !=="qr.hr" || url.pathname.length !== 5) return;
      navigate(url.pathname);
    }
  };

  return (
    <PageWrapper>
      <TopAppBar t={t} />
      <QrScanner open={qrOpen.show} onResult={onResult} />{" "}
      <div style={{ width: "100%" }} />
      <AppBar
        elevation={0}
        position="fixed"
        color="primary"
        sx={{ top: "auto", bottom: 0 }}
      >
        <Toolbar sx={{ width: "100%" }}>
          <StyledFab
            variant="extended"
            size="small"
            color="secondary"
            onClick={scanQR}
          >
            <CameraIcon sx={{ mr: 1 }} />
            {qrOpen.show ? t("cancel") : t("scanQR")}
          </StyledFab>

          <div style={{ flexGrow: 1 }}></div>
          <UserMenu />
        </Toolbar>
      </AppBar>
    </PageWrapper>
  );
};

export default Home;
