import React, { useState } from "react";
import { useRef, useEffect } from "react";
import "./InstaZoom.css";
import styled from "styled-components";

const distance = (event) => {
  return Math.hypot(
    event.touches[0].pageX - event.touches[1].pageX,
    event.touches[0].pageY - event.touches[1].pageY
  );
};

const InstaZoom = ({
  src,
  onImgLoaded,
  padding = 50,
  speed = 15,
  slowdown = 1.1,
}) => {
  const animateRef = useRef();
  const viewRef = useRef();
  const imgRef = useRef();
  const srcRef = useRef(src);
  const start = {};
  let lastTouchStart = 0;
  let imageElementScale = 1.0;
  let lastScale = 1.0;
  let deltaX = 0;
  let deltaY = 0;
  let lastX = 0;
  let lastY = 0;
  let isActive = useRef(false);
  let speedVector = {};
  let dragStart = null;
  let dragEnd = null;
  let dragStartTime;

  const imgSizeRef = useRef({});
  const viewSizeRef = useRef({});

  useEffect(() => {
    const { current: imageElement } = imgRef;
    if (srcRef.current !== src) {
      imageElement.classList.remove("loaded");
      srcRef.current = src;
      isActive.current = true;
      update(0, 0);
    }
  }, [src]);

  useEffect(() => {
    return () => cancelAnimationFrame(animateRef.current);
  }, []);

  const detectDoubleTap = (ev) => {
    let result = false;
    const time = new Date().getTime();
    if (time - lastTouchStart < 200) {
      ev.stopPropagation();
      result = true;
    }
    lastTouchStart = time;
    return result;
  };

  const update = (dx, dy) => {
    const { current: imageElement } = imgRef;
    const transform = `translate3d(${dx}px, ${dy}px, 0) scale(${imageElementScale})`;
    if (imageElement && imageElement.style) {
      imageElement.style.transform = transform;
      imageElement.style.WebkitTransform = transform;
    }
  };

  const imageLoaded = () => {
    const { current: imageElement } = imgRef;
    const { current: viewElement } = viewRef;
    imgSizeRef.current = {
      w: imageElement.offsetWidth,
      h: imageElement.offsetHeight,
    };
    viewSizeRef.current = {
      w: viewElement.offsetWidth,
      h: viewElement.offsetHeight,
    };
    padding = viewSizeRef.current.w / 2;
    imageElement.classList.add("loaded");
    lastScale = 1;
    lastX = deltaX = 0;
    lastY = deltaY = 0;
    dragStart = null;
    lastTouchStart = 0;

    update(deltaX, deltaY)
    if (onImgLoaded) onImgLoaded();
  };

  const clampToBounds = () => {
    const { current: imgSize } = imgSizeRef;
    const { current: viewSize } = viewSizeRef;
    const maxX = (viewSize.w * imageElementScale - imgSize.w) / 2 + padding;
    if (deltaX > maxX) deltaX = maxX;
    else if (deltaX < -maxX) deltaX = -maxX;

    const maxY = (imgSize.h * imageElementScale - imgSize.h) / 2 + padding;
    if (deltaY > maxY) deltaY = maxY;
    else if (deltaY < -maxY) deltaY = -maxY;
  };

  const animate = () => {
    if (isActive.current || (speedVector.x === 0 && speedVector.y === 0))
      return;
    deltaX += speedVector.x * imageElementScale;
    deltaY += speedVector.y * imageElementScale;

    speedVector.x /= slowdown;
    speedVector.y /= slowdown;
    if (Math.abs(speedVector.x) < 0.2) speedVector.x = 0;
    if (Math.abs(speedVector.y) < 0.2) speedVector.y = 0;
    clampToBounds();

    update(deltaX, deltaY);
    lastX = deltaX;
    lastY = deltaY;
    animateRef.current = window.requestAnimationFrame(animate);
  };

  const handleTouchStart = (event) => {
    //event.stopPropagation();
    cancelAnimationFrame(animateRef.current);
    const { current: imageElement } = imgRef;
    if (event.touches.length === 2) {
      dragStart = null;
      imageElement.classList.remove("single");
      lastTouchStart = 0;
      // Calculate where the fingers have started on the X and Y axis
      start.x = (event.touches[0].pageX + event.touches[1].pageX) / 2;
      start.y = (event.touches[0].pageY + event.touches[1].pageY) / 2;
      start.distance = distance(event);
    } else {
      start.x = event.touches[0].pageX;
      start.y = event.touches[0].pageY;
      dragStart = { x: start.x, y: start.y };
      if (detectDoubleTap(event)) {
        imageElement.classList.add("single");
        if (imageElementScale >= 3) {
          imageElementScale = 1;
          deltaX = 0;
          deltaY = 0;
        } else {
          imageElementScale = Math.min(imageElementScale * 2, 4);
        }
        update(deltaX, deltaY);
      } else {
        imageElement.classList.remove("single");
      }
    }
    isActive.current = true;
    dragStartTime = new Date().getTime();
  };

  const handleTouchMove = (event) => {
    // event.stopPropagation();
    if (!isActive.current) return;
    if (event.touches.length === 2) {
      // Safari provides event.scale as two fingers move on the screen
      // For other browsers just calculate the scale manually
      let scale;
      if (event.scale) {
        scale = event.scale;
      } else {
        const deltaDistance = distance(event);
        scale = deltaDistance / start.distance;
      }
      imageElementScale = Math.min(Math.max(0.5, scale * lastScale), 4);

      // Calculate how much the fingers have moved on the X and Y axis
      deltaX =
        ((event.touches[0].pageX + event.touches[1].pageX) / 2 - start.x) *
          1.0 +
        lastX; // x2 for accelarated movement
      deltaY =
        ((event.touches[0].pageY + event.touches[1].pageY) / 2 - start.y) *
          1.0 +
        lastY; // x2 for accelarated movement
    } else {
      deltaX = (event.touches[0].pageX - start.x) * 1.0 + lastX; // x2 for accelarated movement
      deltaY = (event.touches[0].pageY - start.y) * 1.0 + lastY; // x2 for accelarated movement
      dragEnd = { x: event.touches[0].pageX, y: event.touches[0].pageY };
    }
    clampToBounds();
    update(deltaX, deltaY);
  };

  const handleTouchEnd = (event) => {
    // event.stopPropagation();
    const { current: imageElement } = imgRef;
    isActive.current = false;
    if (dragStart !== null && dragEnd !== null) {
      speedVector = { x: dragEnd.x - dragStart.x, y: dragEnd.y - dragStart.y };
      let dragTime = new Date().getTime() - dragStartTime;
      speedVector.x = (speedVector.x / dragTime) * speed;
      speedVector.y = (speedVector.y / dragTime) * speed;
      dragStart = null;
      dragEnd = null;
      animateRef.current = window.requestAnimationFrame(animate);
    }

    if (imageElementScale < 1) {
      imageElementScale = 1;
      deltaX = 0;
      deltaY = 0;
      imageElement.classList.add("single");
      update(deltaX, deltaY);
    }
    lastScale = imageElementScale;
    lastX = deltaX;
    lastY = deltaY;
  };

  useEffect(() => {
    const { current: viewElement } = viewRef;
    viewElement.addEventListener("touchstart", handleTouchStart, {
      passive: true,
    });
    viewElement.addEventListener("touchmove", handleTouchMove, {
      passive: true,
    });
    viewElement.addEventListener("touchend", handleTouchEnd, { passive: true });
    return () => {
      viewElement.removeEventListener("touchstart", handleTouchStart);
      viewElement.removeEventListener("touchmove", handleTouchMove);
      viewElement.removeEventListener("touchend", handleTouchEnd);
    };
  }, []);

  return (
    <div className="pze-container">
      <div ref={viewRef} className="pze-viewport">
        <img
          style={{ boxShadow: " 0px 0px 10px 0px #000" }}
          ref={imgRef}
          src={src}
          onLoad={() => {
            imageLoaded();
          }}
          alt="flyer"
        />
      </div>
    </div>
  );
};

export default InstaZoom;
