import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./A2HS.css";

const isIos = () => {
  const isInWebAppiOS = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(isInWebAppiOS);
};

const isInStandaloneMode = () => {
  const isInWebAppChrome = window.matchMedia(
    "(display-mode: standalone)"
  ).matches;

  return navigator.standalone || isInWebAppChrome;
};

const A2HS = () => {
  const deferredPrompt = useRef();
  const [state, setState] = useState({ shown: false, visible: false });
  const { t } = useTranslation();

  const handleInstall = (result) => {
    if (result && !isIos()) {
      deferredPrompt.current.prompt();
      deferredPrompt.current.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the A2HS prompt");
        } else {
          console.log("User dismissed the A2HS prompt");
        }
        deferredPrompt.current = null;
      });
    }

    setState({ shown: true, visible: false });
  };

  useEffect(() => {
    if (!isInStandaloneMode()) {
      window.addEventListener("beforeinstallprompt", (e) => {
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        // Stash the event so it can be triggered later.
        deferredPrompt.current = e;
        setState({ shown: true, visible: true });
      });
      if (isIos()) {
        setTimeout(() => {
          setState({ shown: true, visible: true });
        }, 2000);
      }
    }
  }, []);

  return (
    <div className={`a2hs-overlay ${state.visible ? "visible" : ""}`}>
      <div className="a2hs-dialog">
        <div className="title">{t("addToHome")}</div>
        <div className="button-group">
          <img alt="icon" className="icon" src="/maskable_icon_x72.png" />
          <p>{t("addToHomeDesc")}</p>
        </div>
        <div className={`button-group ${isIos() ? "ios" : ""}`}>
          {!isIos() && (
            <button onClick={() => handleInstall(false)}>{t("cancel")}</button>
          )}
          <button onClick={() => handleInstall(true)}>
            {isIos() ? t("ok") : t("add")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default A2HS;
