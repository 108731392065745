import * as React from 'react';
import styled from 'styled-components';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import { Button, darken } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import CallIcon from '@mui/icons-material/Call';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CustomMenu = styled(Menu)(
    ({ theme }) => `
& .MuiMenu-paper{
color: ${theme.palette.primary.contrastText};
background: ${theme.palette.primary.main};

:hover {
color: ${darken(theme.palette.primary.contrastText, 0.2)};
}
}
`,
);

export default function FlyerMenu({ flyer = {} }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const navigate = useNavigate();
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLocations = () => {
        setAnchorEl(null);
        navigate('/locations');
    };

    return (
        <div>
            <Button color="secondary" variant="contained" size="small" sx={{maxHeight: '32px',maxWidth:"42px", minWidth:"42px"}} onClick={handleClick}>
                <MenuIcon />
            </Button>
            <CustomMenu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {flyer?.menu?.tel && (
                    <MenuItem onClick={handleClose}>
                        <ListItemIcon>
                            <CallIcon fontSize="small" />
                        </ListItemIcon>
                        <Link href={`tel:${flyer.menu.tel}`} sx={{ color: '#000' }}>
                            <ListItemText>Call</ListItemText>
                        </Link>
                    </MenuItem>
                )}

                {flyer?.menu?.locations && (
                    <MenuItem onClick={handleLocations}>
                        <ListItemIcon>
                            <LocationOnIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Locations</ListItemText>
                    </MenuItem>
                )}
            </CustomMenu>
        </div>
    );
}
