import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import styled from "styled-components";
import { Button, Toolbar } from "@mui/material";
import useSharedState from "../hooks/useSharedState";
import InstaZoom from "../components/InstaZoom";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import FlyerMenu from "../components/FlyerMenu";
import NearbyErrorIcon from "@mui/icons-material/NearbyError";
import { useTranslation } from "react-i18next";
import db from "../core/db";
import CircularProgress from "@mui/material/CircularProgress";
import { alpha } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const FlyerContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(p) => p.theme.palette.primary.main};
  display: flex;
  flex-direction: column;
`;

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  /* margin: 42px 0px; */
  &.notfound {
    align-items: center;
    justify-content: center;
    font-size: 28px;
    font-weight: 700;
  }
`;

const LoadingContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(p) => p.theme.palette.primary.main};
  width: 100%;
  height: 100%;
  z-index: 200;
`;

const InfoWrapper = styled.div`
  position: relative;
  /* left: 0;
  top: 0; */
  width: 100%;
  height: 42px;
  background-color: ${(p) => alpha(p.theme.palette.secondary.main, 1.0)};
  color: ${(p) => p.theme.palette.secondary.contrastText};
  /* z-index: 2000; */
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  font-weight: 500;
  line-height: 16px;

  &.shadow {
    box-shadow: 0px 8px 15px -16px #000000;
  }

  & .stroke {
    /* text-shadow: -1px -1px 0 #fff, 1px -1px 0 #aaa, -1px 1px 0 #fff, 1px 1px 0 #aaa; */
  }

  & .pages {
    padding: 4px;
  }

  & .title {
    font-size: 14px;
  }

  & .subtitle {
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
  }

  &.bottom {
    /* background-color: ${(p) => alpha(p.theme.palette.primary.main, 0.8)};
    color: ${(p) => p.theme.palette.primary.contrastText}; */
    /* top: auto;
    bottom: 0; */
  }
`;

const Flyer = () => {
  const [flyer, setFlyer] = useState({
    id: "",
    name: "",
    pages: [],
    currentPage: 0,
  });

  const [isLoading, setLoading] = useState(true);
  const flyerFetched = useRef(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useParams();
  const btnPrevRef = useRef();

  useEffect(() => {
    async function getFlyer() {
      try {
        const flyerLink = `/flyers/${params.flyerId}.json`;
        const res = await fetch(flyerLink);
        if (res.ok) {
          let flyerData = await res.json();

          const exFly = await db.flyers
            .where("fid")
            .equals(flyerData.id)
            .first();
          if (!exFly) {
            await db.flyers.add({
              fid: flyerData.id,
              name: flyerData.name,
              link: flyerLink,
            });
            toast.success(t("flyerSaved"), { duration: 3000 });
          }

          flyerData.currentPage = 0;
          setFlyer(flyerData);
          setTimeout(() => {
            setLoading(false);
          }, 500);
        }
      } catch (ex) {
        setFlyer({ id: null, currentPage: 0 });
        setLoading(false);
      }
    }
    if (!flyerFetched.current) {
      flyerFetched.current = true;
      getFlyer();
    }
  }, [flyer.id]);

  const handlePrev = (evt) => {
    evt.preventDefault();
    setLoading(true);
    setFlyer((pState) => {
      return { ...pState, currentPage: pState.currentPage - 1 };
    });
  };

  const handleNext = (evt) => {
    evt.preventDefault();
    setLoading(true);
    setFlyer((pState) => {
      return { ...pState, currentPage: pState.currentPage + 1 };
    });
  };

  return (
    <>
      <FlyerContainer>
        <InfoWrapper style={{ paddingLeft: "8px", paddingRight: "8px" }}>
          <Button
            color="primary"
            variant="text"
            size="small"
            sx={{
              maxHeight: "32px",
              maxWidth: "42px",
              minWidth: "42px",
              paddingLeft: "0px",
            }}
          >
            <ArrowBackIcon
              sx={{ fontSize: 32 }}
              onClick={() => {
                navigate(-1);
              }}
            />
          </Button>
          {flyer?.id ? (
            <>
              <div style={{ flexGrow: "1" }}>
                <div className="title stroke">{flyer.title}</div>
                <div className="subtitle stroke">{flyer.subtitle}</div>
              </div>
              <div style={{ width: "42px", height: "1px" }} />
            </>
          ) : (
            <div style={{ flexGrow: 1 }}></div>
          )}
        </InfoWrapper>

        <StyledContainer className={flyer?.id ? "" : "notfound"}>
          {isLoading && (
            <LoadingContainer className="loadingContainer">
              <CircularProgress color="success" />
            </LoadingContainer>
          )}
          {flyer?.id ? (
            <InstaZoom
              key={flyer.currentPage}
              onImgLoaded={() => {
                setLoading(false);
              }}
              //   key={currentPage}
              src={flyer.pages[flyer.currentPage]}
            />
          ) : flyer.id === null ? (
            <>
              <NearbyErrorIcon color="error" fontSize="large" />
              {t("flyerNotFound")}
            </>
          ) : null}
        </StyledContainer>

        <InfoWrapper className="bottom">
          {flyer?.id && (
            <Toolbar
              sx={{
                width: "100%",
                paddingLeft: "12px",
                paddingRight: "12px",
                display: "flex",
                justifyContent: "space-between",
                minHeight: "42px",
              }}
            >
              <div style={{ width: "56px" }}></div>
              <div style={{ display: "flex" }}>
                <Button
                  ref={btnPrevRef}
                  color="primary"
                  variant="text"
                  size="small"
                  disableElevation
                  sx={{
                    margin: "0px 16px",
                    maxHeight: "32px",
                    maxWidth: "42px",
                    minWidth: "42px",
                  }}
                  disabled={flyer.currentPage === 0}
                  onTouchEnd={handlePrev}
                >
                  <ArrowBackIosIcon sx={{ fontSize: 24, marginLeft: "8px" }} />
                </Button>
                <div className="subtitle stroke pages">
                  {flyer.currentPage + 1}/{flyer.pages.length}
                </div>
                <Button
                  color="primary"
                  variant="text"
                  size="small"
                  disableElevation
                  sx={{
                    margin: "0px 16px",
                    maxHeight: "32px",
                    maxWidth: "42px",
                    minWidth: "42px",
                  }}
                  disabled={flyer.currentPage === flyer.pages.length - 1}
                  onTouchEnd={handleNext}
                >
                  <ArrowForwardIosIcon sx={{ fontSize: 24 }} />
                </Button>
              </div>
              <FlyerMenu flyer={flyer} />
            </Toolbar>
          )}
        </InfoWrapper>
      </FlyerContainer>
    </>
  );
};

export default Flyer;
