import Dexie, { Table } from 'dexie';


export class DexieDB extends Dexie {
   constructor() {
    super('qr-hr');
    this.version(4).stores({
        flyers: '++id, name, fid,  link', // Primary key and indexed props
    });
  }
}

const db = new DexieDB();

export default db;