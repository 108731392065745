import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

export default function ConfirmDialog({
  confirmText,
  cancelText,
  title,
  content,
  open,
  resultCb,
  userData,
}) {
  //   const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();

  const handleClose = (ev, res) => {
    const value = ev.target.value;
    resultCb && resultCb(value, userData);
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "90%", backgroundColor:"#eee", color:"#000" } }}
      maxWidth="xs"
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      <DialogContent dividers>
        {content.map((line, idx) => (
          <DialogContentText key={idx}>{line}</DialogContentText>
        ))}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" autoFocus onClick={handleClose} value="0">
          {cancelText || t("cancel")}
        </Button>
        <Button color="secondary" onClick={handleClose} autoFocus value="1">
          {confirmText || t("ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
